<template>
  <hcc-modal
    :title="$t('shop.product.wholesale.create')"
    name="form-wholesale"
    :width="300"
    @confirm="createOrUpdateWholesale"
    @cancel="clear"
    @closed="clear"
    :resizable="true"
    :confirmDisableButton="!validForm"
  >
    <div class="user__property">
      <hcc-input
        type="number"
        min="0"
        v-model.number="form.minimumAmount"
        :label="$t('shop.product.wholesale.minimumAmount')"
        class="add-template__row"
        @blur="$v.form.minimumAmount.$touch()"
        :error="$v.form.minimumAmount.$error"
      />
      <template v-if="$v.form.minimumAmount.$error">
        <p v-if="!$v.form.minimumAmount.required" class="user__error">
          {{ $t('shop.errors.required', { field: $t('shop.product.wholesale.minimumAmount') }) }}
        </p>
        <p v-else-if="!$v.form.minimumAmount.minValue" class="user__error">
          {{ $t('shop.product.wholesale.errors.less-than-one-min') }}
        </p>
      </template>
      <hcc-input
        type="number"
        min="0"
        v-model.number="form.maxAmount"
        :label="$t('shop.product.wholesale.maximumAmount')"
        class="add-template__row"
        @blur="$v.form.maxAmount.$touch()"
        :error="$v.form.maxAmount.$error"
      />
      <template v-if="$v.form.maxAmount.$error">
        <p v-if="!$v.form.maxAmount.required" class="user__error">
          {{ $t('shop.errors.required', { field: $t('shop.product.wholesale.minimumAmount') }) }}
        </p>
        <p v-else-if="!$v.form.maxAmount.minValue" class="user__error">
          {{ $t('shop.product.wholesale.errors.less-than-one-max') }}
        </p>
      </template>
      <hcc-money
        :placeholder="$t('shop.product.wholesale.price')"
        v-model.number="form.price"
        :label="$t('shop.product.wholesale.price')"
        class="add-template__row"
        @blur="$v.form.price.$touch()"
        :error="$v.form.price.$error"
        :errorMessage="
          $t('shop.errors.required', { field: $t('shop.product.wholesale.price') })
        "
      />
      <div class="shop__container">
        <div class="shop__group">
          <span>{{ $t("shop.product.status") }}</span>
          <div class="shop__status">
            <hcc-toggle-switch
              name="toggle-wholesale"
              :value="form.status"
              @change="toggleStatus"
            />
          </div>
        </div>
      </div>
    </div>
  </hcc-modal>
</template>

<script>
import numeral from 'numeral';
import { required, minValue } from 'vuelidate/lib/validators';
import { maxLengthText } from '@/utils/helper';
import addWholesalePriceInProductGql from '@/graphql/mutations/product/addWholesalePriceInProduct.gql';
import editWholesalePriceInProductGql from '@/graphql/mutations/product/editWholesalePriceInProduct.gql';

export default {
  components: {
    HccModal: () => import('@/components/shared/HccModal/index.vue'),
    HccInput: () => import('@/components/shared/HccInput/index.vue'),
    HccMoney: () => import('@/components/shared/HccMoney/index.vue'),
    HccToggleSwitch: () => import('@/components/shared/HccToggleSwitch/index.vue'),
  },
  props: {
    dataWholesale: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: true,
      form: {
        minimumAmount: '',
        maxAmount: '',
        price: '',
        status: false,
      },
    };
  },
  validations: {
    form: {
      minimumAmount: {
        required,
        minValueValue: minValue(1),
      },
      maxAmount: {
        required,
        minValueValue: minValue(1),
      },
      price: { required },
    },
  },
  computed: {
    validForm() {
      if (this.$v.$invalid) return !this.$v.$invalid;
      return this.form.maxAmount > this.form.minimumAmount;
    },
    idProduct() {
      return this.$route.params.idProduct;
    },
  },
  watch: {
    dataWholesale(newValue) {
      this.form.minimumAmount = newValue.minimumAmount;
      this.form.maxAmount = newValue.maxAmount;
      this.form.price = newValue.price;
      this.form.status = newValue.status;
    },
    'form.minimumAmount': function setminimumAmount(newValue) {
      this.form.minimumAmount = Number(maxLengthText(newValue, 15));
    },
    'form.maxAmount': function setminimumAmount(newValue) {
      this.form.maxAmount = Number(maxLengthText(newValue, 15));
    },
  },
  methods: {
    async toggleStatus() {
      this.form.status = !this.form.status;
    },
    async createWholesale() {
      await this.$apollo.mutate({
        mutation: addWholesalePriceInProductGql,
        variables: {
          idProduct: this.idProduct,
          ...this.form,
        },
      });
    },
    convertMoneyToNumber() {
      this.form.price = numeral(this.form.price).value();
    },
    async editWholesale() {
      await this.$apollo.mutate({
        mutation: editWholesalePriceInProductGql,
        variables: {
          wholesalePriceId: this.dataWholesale.id,
          ...this.form,
        },
      });
    },
    async createOrUpdateWholesale() {
      this.convertMoneyToNumber();
      if (!this.dataWholesale.id) await this.createWholesale();
      else await this.editWholesale();
      this.clear();
      this.$emit('onEditWholesale');
    },
    async clear() {
      this.form.minimumAmount = '';
      this.form.maxAmount = '';
      this.form.price = '';
      this.form.status = false;
      this.$v.$reset();
    },
  },
};
</script>
<style scoped lang="scss">
@import "~styles/components/_user.scss";
</style>
